import HTTPREQUEST,{ContentType} from "../http";
export const good = {

  /**
   * 产品页
   */
  GetDetial(id) {
    return HTTPREQUEST.get("/Good/GetDetial", {
      id,
      "data": ""
    });
  },
  
  /**
   * 全局搜索，返回色号 产品 文章
   */
  GetAllCategories(){
    return HTTPREQUEST.post("/Good/GetGoodsCategoryTree");
  },

  /**
   * 产品列表
   */
  GetGoodsPageList(where,order,page,limit){
    return HTTPREQUEST.post("/Good/GetGoodsPageList",{
    page,
    limit,
    order,
    where});
  },

  /**
   * 获取品牌
   */
  GetBrands(){
    return HTTPREQUEST.post("/Good/GetBrands");
  }
  
};
